import { Copy } from "lucide-react";
import React from "react";
import { toast } from "sonner";

type Props = {
  label: string;
  placeholder: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  isMagicLink?: boolean;
};

const Input = ({
  label,
  placeholder,
  value,
  setValue,
  disabled,
  isMagicLink,
}: Props) => {
  const baseUrl = "https://www.magicreview.ai";

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(baseUrl + "/" + value);
      toast.success("Copied to clipboard");
    } catch {
      toast.error("Failed copy");
    }
  };

  return (
    <div className="w-full">
      <label className="text-[#000] text-lg font-pro font-medium mb-[10px] flex gap-1">
        {label}
      </label>
      <div className="relative">
        <input
          className="border-[1px] border-[#E7E4E4] rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5]"
          type="text"
          placeholder={placeholder}
          value={value || ""}
          onChange={(e) => setValue(e.target.value)}
          disabled={disabled}
        />
        {isMagicLink && (
          <Copy
            className="size-5 absolute top-4 right-5 cursor-pointer"
            onClick={handleCopy}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
