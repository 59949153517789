import { Check, HandPlatterIcon, Trash2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import AddPlanDialog from "../../components/plans/AddPlanDialog";
import { useDeletePlans, useGetPlans } from "../../query/useSubscription";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import { toast } from "sonner";
import { AxiosError } from "axios";
import EditPlanDialog from "../../components/plans/EditPlanDialog";
import { generateRandomString } from "../../lib/utils";
import PasswordModal from "../../components/users/PasswordModal";
import CustomDropDownForPlans from "../../components/users/CustomDropDownForPlans";

const pricingData = [
  "Instant tap to get AI generated reviews",
  "Real-Time Tracking of Customer Reviews",
  "Hassle-Free Review Collection under 30 seconds",
  "Faster Reviews Without Manual Typing",
];

const Plans = () => {
  const [isOpenAddPlanDialog, setIsOpenAddPlanDialog] =
    useState<boolean>(false);
  const [isOpenEditPlanDialog, setIsOpenEditPlanDialog] =
    useState<boolean>(false);
  const [isOpenDeletePlanDialog, setIsOpenDeletePlanDialog] =
    useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [isOpenPassword, setIsOpenPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("AED");

  const { data: plans, refetch } = useGetPlans();
  const { mutate: deletePlan, isPending: deletePlanPending } = useDeletePlans();

  const currencies = useMemo(() => {
    return [...new Set(plans?.map((item) => item?.currency))];
  }, [plans]);

  const handleDeletePlan = () => {
    setPasswordError("");
    deletePlan(
      { id: selectedPlan, password: password },
      {
        onSuccess: (res) => {
          if (res?.message) {
            toast.success(res?.message);
          } else {
            toast.success("Plan has been deleted");
          }
          setIsOpenDeletePlanDialog(false);
          setIsOpenPassword(false);
          setPassword("");
          setPasswordError("");
          refetch();
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.status === "field error") {
              if (err.response.data.message) {
                setPasswordError(err.response.data.message?.password?.[0]);
              }
            } else {
              if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                toast.error("Something went wrong");
              }
            }
          }
        },
      }
    );
  };

  useEffect(() => {
    if (plans) {
      setSelectedPlan(
        plans?.filter((item) => item?.currency === selectedCurrency)?.[0]?.uuid
      );
    }
  }, [plans, selectedCurrency]);

  return (
    <div className="w-full min-h-[calc(100vh-150px)] bg-lightpurple rounded-2xl p-5 lg:px-10 lg:py-5">
      <div className="flex items-center gap-x-5 mb-5">
        <p>Currency:</p>
        <CustomDropDownForPlans
          values={currencies}
          value={selectedCurrency}
          setValue={setSelectedCurrency}
        />
      </div>
      <div className="flex items-center gap-x-5">
        <button
          className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
          onClick={() => {
            setIsOpenAddPlanDialog(true);
          }}
        >
          Add New Plan
        </button>
        <div className="p-2.5 rounded-[12px] border cursor-pointer">
          <Trash2
            className="w-6 h-6 text-[#525252]"
            onClick={() => {
              setIsOpenDeletePlanDialog(true);
            }}
          />
        </div>
      </div>

      <div className="w-full relative font-poppins container text-[#333333] space-y-6  z-10 mt-5 lg:mt-10">
        <div className="bg-white shadow-sm rounded-[33px] max-w-[716px] w-full px-5 py-5 space-y-6 lg:py-[70px] m-auto">
          <div className="w-[90%] lg:w-[70%] mx-auto flex flex-col justify-center items-center">
            <div className="bg-lightpurple w-full flex-wrap flex justify-center items-center py-3 px-5 rounded-[12px]">
              {plans
                ?.filter((item) => item?.currency === selectedCurrency)
                ?.sort((a, b) => a?.price - b?.price)
                ?.map((item) => {
                  return (
                    <span
                      key={item?.uuid}
                      className={`rounded-lg flex justify-center items-center px-3 py-3 flex-shrink-0 ${
                        selectedPlan === item?.uuid && "bg-[#4f75fa] text-white"
                      } cursor-pointer transition-all w-full lg:w-fit text-center duration-500`}
                      onClick={() => setSelectedPlan(item?.uuid)}
                    >
                      {item?.short_text}
                    </span>
                  );
                })}
            </div>

            {plans
              ?.filter((item) => item?.currency === selectedCurrency)
              ?.filter((item) => item?.uuid === selectedPlan)
              ?.map((item, i) => (
                <>
                  <div
                    className="px-5 py-2 text-[#FF0000] border border-[#FF0000] rounded-[18px] mt-[44px] font-poppins text-base"
                    key={item?.id}
                  >
                    {item?.secondary_text}
                  </div>
                  <div className="px-3 py-1 text-[#73DF07] border border-[#73DF07] rounded-[10px] font-poppins text-sm mt-3">
                    {item?.duration_text}
                  </div>

                  <h2 className="text-[#333333] font-poppins text-[40px] lg:text-[67px] font-extrabold text-center">
                    {item?.currency !== "FREE"
                      ? (item?.discount !== 0
                          ? item?.price_after_discount
                          : item?.price
                        )?.toLocaleString("en-US", {
                          style: "currency",
                          currency: item?.currency,
                          maximumFractionDigits: 0,
                        })
                      : item?.price}
                  </h2>
                  {item?.discount !== 0 ? (
                    item?.is_relative_discount_price ? (
                      <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
                        <h2 className="text-[26px] md:text-[32px] text-[#333333] line-through">
                          {item?.currency !== "FREE"
                            ? item?.price?.toLocaleString("en-US", {
                                style: "currency",
                                currency: item?.currency,
                                maximumFractionDigits: 0,
                              })
                            : item?.price}
                        </h2>
                        <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-base  md:text-[20px] ">
                          {item?.discount}% OFF{" "}
                        </button>
                      </div>
                    ) : (
                      <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
                        <h2 className="text-[26px] md:text-[32px] text-[#333333] line-through text-nowrap">
                          {item?.currency !== "FREE"
                            ? item?.price?.toLocaleString("en-US", {
                                style: "currency",
                                currency: item?.currency,
                                maximumFractionDigits: 0,
                              })
                            : item?.price}
                        </h2>
                        <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-base  md:text-[20px] text-nowrap ">
                          {/* {item?.discount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: item?.currency,
                            maximumFractionDigits: 0,
                          })}{" "} */}
                          {item?.discount_percent}% OFF{" "}
                        </button>
                      </div>
                    )
                  ) : null}
                  <div className=" w-full divide-y">
                    {pricingData.map((item, index) => (
                      <div
                        key={item}
                        className={`flex font-poppins text-[#1F1E1E] lg:items-center items-start text-start gap-x-3 w-full py-3`}
                      >
                        <div className="w-6 h-6 rounded-full bg-[#4f75fa] flex justify-center items-center flex-shrink-0">
                          <Check className="text-white w-4 h-4" />
                        </div>
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                  <button
                    className="bg-[#4F75FA] border-[#4F75FA] hover:text-[#4F75FA] transition-all duration-300 hover:bg-white border  font-poppins w-full mt-10 py-3 rounded-[10px] text-white"
                    onClick={() => setIsOpenEditPlanDialog(true)}
                  >
                    Edit Plan
                  </button>
                </>
              ))}
          </div>
        </div>
      </div>

      <AddPlanDialog
        isOpenDialog={isOpenAddPlanDialog}
        setIsOpenDialog={setIsOpenAddPlanDialog}
        refetch={refetch}
        key={generateRandomString(2)}
      />
      <EditPlanDialog
        isOpenDialog={isOpenEditPlanDialog}
        setIsOpenDialog={setIsOpenEditPlanDialog}
        refetch={refetch}
        data={plans?.find((item) => item.uuid === selectedPlan)}
        key={selectedPlan}
      />
      <ConfirmDialog
        isOpenDialog={isOpenDeletePlanDialog}
        setIsOpenDialog={setIsOpenDeletePlanDialog}
        title={`Are you sure you want to delete the ${
          plans?.find((item) => item?.uuid === selectedPlan)?.short_text
        } plan?`}
        confirmBtnText="Delete"
        confirmAction={() => {
          setIsOpenDeletePlanDialog(false);
          setIsOpenPassword(true);
        }}
        isPending={deletePlanPending}
      />
      <PasswordModal
        isOpenDialog={isOpenPassword}
        setIsOpenDialog={setIsOpenPassword}
        confirmAction={handleDeletePlan}
        isLoading={deletePlanPending}
        value={password}
        setValue={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
      />
    </div>
  );
};

export default Plans;
