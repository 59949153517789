import { Search } from "lucide-react";
import UserDetails from "../../components/users/UserDetails";
import { useGetUser } from "../../query/useAuth";
import {
  useGetUserCount,
  useGetUserList,
  useGetUserListInfinite,
} from "../../query/useUser";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { config } from "../../config/config";
import { GetUserListReturn } from "../../types/types";
import { cn, dayFormatter } from "../../lib/utils";
import useDebounce from "../../hooks/useDebounce";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

const Users = () => {
  const baseUrl = config.apiUrl;
  const { inView, ref } = useInView();
  const [email, setEmail] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<
    GetUserListReturn | undefined
  >();
  const [index, setIndex] = useState(0);

  const debouncedValue = useDebounce(email, 500);

  const { data: users } = useGetUserList({
    email: debouncedValue,
    index: index,
  });

  const {
    data,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    fetchPreviousPage,
  } = useGetUserListInfinite({
    email: debouncedValue,
  });

  const { data: userCount } = useGetUserCount();

  useEffect(() => {
    if (hasNextPage && inView) {
      fetchNextPage();
    }
  }, [inView]);

  const flattenedData = data?.pages?.flatMap((page) => page?.results);

  // useEffect(() => {
  //   setSelectedUser(flattenedData?.[0]);
  // }, [flattenedData?.[0]]);

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  return (
    <div
      className={`w-full rounded-2xl min-h-[calc(100vh-135px)]  space-y-5 ${
        selectedUser && "bg-lightpurple py-5"
      }`}
    >
      {!selectedUser && (
        <>
          <div className="rounded-lg lg:rounded-2xl border mt-5 bg-white">
            <Table>
              <TableHeader>
                <TableRow className="text-[#4d4d4d] bg-lightpurple">
                  <TableHead></TableHead>
                  <TableHead>First Name</TableHead>
                  <TableHead>Last Name</TableHead>
                  <TableHead>Email Address</TableHead>
                  <TableHead>Registration Date</TableHead>
                  <TableHead>Subscription</TableHead>
                  <TableHead>Expiry Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {(users?.total_results as number) <= 0 ? (
                  <TableRow className="text-[#4a4a4a] text-sm">
                    <TableCell colSpan={6}>
                      <p className="text-center">No user yet!</p>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {users?.results.map((item) => (
                      <TableRow
                        className={`text-[#4a4a4a] text-sm hover:bg-gray-100 cursor-pointer`}
                        key={item.id}
                        onClick={() => setSelectedUser(item)}
                      >
                        <TableCell>
                          <img
                            className="size-8 shrink-0 rounded-full"
                            src={
                              item?.profile?.profile_picture ||
                              "/magicreview.svg"
                            }
                            alt=""
                          />
                        </TableCell>
                        <TableCell>{item?.first_name}</TableCell>
                        <TableCell>{item?.last_name}</TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell>
                          {item?.entity?.created_at &&
                            dayFormatter(item?.entity?.created_at)}
                        </TableCell>
                        <TableCell>
                          {item?.profile?.current_plan?.short_text}
                        </TableCell>
                        <TableCell>
                          {item?.profile?.subscription_expiration_date &&
                            dayFormatter(
                              (item?.profile
                                ?.subscription_expiration_date as number) * 1000
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </div>
          {(users?.total_results as number) > 10 && (
            <div className="flex justify-between items-center mt-5">
              <button
                className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 disabled:hidden"
                onClick={handlePrev}
                disabled={index <= 0}
              >
                Prev
              </button>
              <button
                className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 disabled:hidden"
                onClick={handleNext}
                disabled={index + 10 > (users?.total_results as number)}
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
      {selectedUser && (
        <div className="flex gap-x-5 h-full flex-wrap px-5">
          <div className="w-full lg:w-[25%] h-full space-y-5">
            <p className="w-full rounded-[12px] px-5 py-2.5 bg-white font-pro">
              Total Users - {userCount?.data?.all_active_users_count}
            </p>
            <div className="relative w-full text-[#615E83]">
              <input
                type="text"
                placeholder="Enter email"
                className="w-full rounded-[12px] px-5 py-2 bg-white"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Search className="w-5 h-5 absolute right-5 top-1/2 -translate-y-1/2" />
            </div>
            <div className="w-full font-pro bg-white rounded-[12px] divide-y lg:min-h-[calc(100vh-290px)] lg:max-h-[calc(100vh-290px)] min-h-[300px] max-h-[300px] overflow-y-auto scrollbar-thin px-5 py-2.5 overflow-x-hidden">
              {flattenedData?.map((item, i) => (
                <div
                  className={cn(
                    "flex items-center justify-between py-2.5 pl-3 cursor-pointer",
                    selectedUser?.id === item?.id && "bg-primary  rounded-lg "
                  )}
                  key={i}
                  onClick={() => setSelectedUser(item)}
                >
                  <div className="flex items-center gap-x-3">
                    <div>
                      <img
                        src={
                          item?.profile?.profile_picture || "/magicreview.svg"
                        }
                        alt=""
                        className="w-[30px] h-[30px] rounded-full flex-shrink-0"
                      />
                    </div>
                    <div className="font-pro">
                      <p
                        className={cn(
                          "text-sm ",
                          selectedUser?.id === item?.id
                            ? "text-white"
                            : "text-[#252323]"
                        )}
                      >
                        {item?.first_name + " " + item?.last_name}
                      </p>
                      <p
                        className={cn(
                          "text-[10px] ",
                          selectedUser?.id === item?.id
                            ? "text-white"
                            : "text-[#888]"
                        )}
                      >
                        {item?.email}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              {hasNextPage && (
                <div ref={ref} className="text-center pt-3">
                  <p className="text-center text-xs text-[#888]">Loading ...</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-full lg:w-[72%]  rounded-[12px] mt-5 lg:mt-0">
            <UserDetails user={selectedUser} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
