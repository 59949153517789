import { Link, Mail, User } from "lucide-react";
import { GetUserEntity, GetUserProfile } from "../../types/types";
import { config } from "../../config/config";
import Input from "./Input";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { PhoneInput } from "../ui/phone-input";
import { useGetIndustryTypes } from "../../query/useMisc";
import { usePutUserUpdate } from "../../query/useUser";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import ButtonLoading from "../common/ButtonLoading";

const Account = ({
  profile,
  entity,
  first_name,
  last_name,
  mail,
}: {
  profile: GetUserProfile;
  entity: GetUserEntity;
  first_name: string | null;
  last_name: string | null;
  mail: string;
}) => {
  const baseUrl = config.apiUrl;
  const queryClient = useQueryClient();
  const [userProfile, setUserProfile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [aboutBusiness, setAboutBusiness] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [industry, setIndustry] = useState("");
  const [phone, setPhone] = useState("");
  const [magicLink, setMagicLink] = useState("");

  const { data: industryTypes } = useGetIndustryTypes();
  const { mutate: userUpdate, isPending: userUpdateLoading } =
    usePutUserUpdate();

  const handleUserUpdate = () => {
    userUpdate(
      {
        first_name: firstName,
        last_name: lastName,
        about_business: aboutBusiness,
        company_link: websiteLink,
        industry: industry,
        location: businessLocation,
        name: companyName,
        phone_number: phone,
        email: email,
      },
      {
        onSuccess: (res) => {
          if (res?.message) {
            toast.success(res?.message);
          } else {
            toast.success("User has been updated");
          }
          queryClient.invalidateQueries({ queryKey: ["getUserList"] });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            }
            if (err.response.data.title) {
              toast.error(err.response.data.title[0]);
            } else {
              toast.error("Something went wrong");
            }
          }
        },
      }
    );
  };

  const handlePhoneChange = (e) => {
    setPhone(e);
  };

  useEffect(() => {
    setUserProfile(profile?.profile_picture);
    setFirstName(first_name);
    setLastName(last_name);
    setEmail(mail);
    setCompanyName(entity?.name);
    setWebsiteLink(entity?.company_link);
    setAboutBusiness(profile?.about_business);
    setBusinessLocation(entity?.location);
    setPhone(profile?.phone_number);
    setIndustry(entity?.industry);
    setMagicLink(entity?.magic_link);
  }, []);
  return (
    <>
      <div className="py-5">
        <p className="font-pro font-medium text-xl">Manage profile settings</p>
      </div>
      <hr />
      {/* <div className="py-5 lg:px-10 space-y-5">
        <div className="flex items-center flex-wrap gap-y-2">
          <div className="w-[200px] flex items-center gap-x-3 text-[#888]">
            <User />
            <p>Name</p>
          </div>
          <input
            type="text"
            className="px-5 py-3 rounded-lg bg-[#f7f7f7] w-[300px]"
            placeholder="Name"
            value={first_name + " " + last_name}
            readOnly
          />
        </div>
        <div className="flex items-center flex-wrap gap-y-2">
          <div className="w-[200px] flex items-center gap-x-3 text-[#888]">
            <Mail />
            <p>Email Address</p>
          </div>
          <input
            type="text"
            className="px-5 py-3 rounded-lg bg-[#f7f7f7] w-[300px]"
            placeholder="Email"
            value={email}
            readOnly
          />
        </div>
        <div className="flex items-center flex-wrap gap-y-2">
          <div className="w-[200px] flex items-center gap-x-3 text-[#888]">
            <Link />
            <p>Magicreview Link</p>
          </div>
          <input
            type="text"
            className="px-5 py-3 rounded-lg bg-[#f7f7f7] w-[300px]"
            placeholder="Magicreview Link"
            value={entity?.magic_link}
            readOnly
          />
        </div>
      </div> */}
      <div
        className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5 lg:gap-x-10 mt-10
          "
      >
        <div className="w-full lg:col-span-2">
          <label className="text-[#000] text-lg font-pro font-medium mb-[10px] flex gap-1">
            Profile photo
          </label>
          <div className="flex items-center gap-x-3">
            <div
              className="relative w-16 h-16 cursor-pointer"
              // onClick={handleChangeProfileClick}
            >
              {/* <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  hidden
                  onChange={handleChangeProfile}
                /> */}
              <img
                className="h-14 w-14 shrink-0 rounded-full mr-[21px] object-cover"
                src={userProfile || "/magicreview.svg"}
                alt=""
              />
              {/* <div className="w-6 h-6 bg-black/80 rounded-full absolute bottom-2 right-1 flex justify-center items-center">
                  <IconEdit className=" w-4 h-4 text-white" />
                </div> */}
            </div>

            {/* <button
                className="font-pro text-[#a7a5a5]"
                onClick={handleChangeProfileClick}
              >
                Upload photo
              </button> */}
          </div>
        </div>
        <Input
          label="First Name"
          placeholder="e.g. John"
          value={firstName}
          setValue={setFirstName}
        />
        <Input
          label="Last Name"
          placeholder="e.g. Doe"
          value={lastName}
          setValue={setLastName}
        />

        <Input
          label="Company Name"
          placeholder="e.g. xyz"
          value={companyName}
          setValue={setCompanyName}
        />
        <Input
          label="Email Address"
          placeholder="e.g. xyz@example.com"
          value={email}
          setValue={setEmail}
          disabled={true}
        />

        <div>
          <label className=" text-[#000] text-lg font-pro font-medium mb-[10px] flex gap-1">
            {"Industry"}
          </label>
          <Select value={industry} onValueChange={(item) => setIndustry(item)}>
            <SelectTrigger className="border border-[#E7E4E4]  rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] h-12 bg-white outline-none">
              <SelectValue placeholder="Select industry type" />
            </SelectTrigger>
            <SelectContent>
              {industryTypes
                ?.filter((item) => item?.industry_identifier !== "other")
                ?.map((item) => (
                  <SelectItem key={item?.id} value={item?.industry_identifier}>
                    {item?.display_text}
                  </SelectItem>
                ))}
              {industryTypes
                ?.filter((item) => item?.industry_identifier === "other")
                ?.map((item) => (
                  <SelectItem key={item?.id} value={item?.industry_identifier}>
                    {item?.display_text}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
        <div className="w-full">
          <label className="text-black text-lg font-pro font-medium mb-[10px] flex gap-1">
            {"Phone Number"}
          </label>
          <PhoneInput
            className=""
            value={phone}
            onChange={handlePhoneChange}
            defaultCountry="GB"
          />
          {/* {phone?.length > 5 && phoneError && (
                  <p className="font-pro text-xs text-red-500 mt-2 flex gap-1">
                    <CircleAlert className="size-4" /> {phoneError}
                  </p>
                )} */}
        </div>

        <Input
          label="Website Link"
          placeholder="e.g. https://www.company.in"
          value={websiteLink}
          setValue={setWebsiteLink}
        />

        <Input
          label="Business Location"
          placeholder="e.g. Dubai"
          value={businessLocation}
          setValue={setBusinessLocation}
        />

        <Input
          label="MagicLink"
          placeholder="e.g. xyz@example.com"
          value={magicLink}
          setValue={setMagicLink}
          disabled={true}
          isMagicLink={true}
        />
        <div className="w-full">
          <label className="text-[#000] text-lg font-pro font-medium mb-[10px] flex gap-1">
            {"About Business"}
          </label>
          <textarea
            className="border-[1px] border-[#E7E4E4] rounded-[8px] pt-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full pr-10 scrollbar-none"
            placeholder={"Write about your business"}
            value={aboutBusiness || ""}
            onChange={(e) => setAboutBusiness(e.target.value)}
            disabled={aboutBusiness?.length >= 1000}
            rows={5}
          />
          <p className="text-right -mt-5 text-gray-500 font-pro text-xs">
            {aboutBusiness?.length || 0} / 1000 characters
          </p>
        </div>
      </div>
      <div className="w-full flex justify-end mt-5">
        <button
          className="bg-primary border border-primary w-[121px] py-3 rounded-[7px] text-white hover:bg-white hover:text-primary transition-all duration-200"
          onClick={handleUserUpdate}
        >
          {userUpdateLoading ? <ButtonLoading /> : "Save"}
        </button>
      </div>
    </>
  );
};

export default Account;
