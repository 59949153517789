import { QueryClientConfig, useQuery } from "@tanstack/react-query";
import { getIndustryTypes } from "../api/misc";

export const useGetIndustryTypes = (config?: QueryClientConfig) => {
  return useQuery({
    queryKey: ["getIndustryTypes"],
    queryFn: () => getIndustryTypes(),
    ...config,
  });
};
